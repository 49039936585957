.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

Nav.Link{
  font-size: 50px !important;
}

.background-jumbotron{
  background-image: url('./img/rock-bg.jpg');
  background-size: cover;
  font-family: 'IBM Plex Serif', serif;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  width: auto;
  text-shadow: 0px 0px 6px  #7a7a7a;
  height: 70vh;
}



.white-text{
  color: white;
}

.banner-btn{
  margin-top: 20px;
}

h1{
  font-size: 50px;
}

.info-container{
  color: rgb(70, 69, 69);
  font-family: 'IBM Plex Serif', serif;
  font-size: 20px;
  line-height: 35px;
}
#inspirational-msg{
  margin-top: 20px;
  margin-bottom:20px;
}

.treatment-container{
  margin-top: 40px;
}

.treatment-row{
  margin-bottom: 30px;
}
.cert-img{

  /* margin-bottom: 50px;  */

  padding-top: 40px;
  padding-bottom: 40px;
}
.col-md-6{
  margin-bottom: 10px;
}

.footer{
  background-color: rgb(42, 42, 58);
  text-align: left;
}

.footer-container{
  margin-top: 30px;
}

.footer-link{
  color: white;
}

.footer-link:hover{
  color: grey;
  text-decoration: none;
}

.card-header{
  text-align: left;
}

.card-title{
  font-weight: 600;
}
.card-header{
  font-size: 20px;
}

.card-body{
  /* background-color: rgb(247, 247, 247); */
  font-size: 18px;
  text-align:left;
}

.info-row{
  font-family: 'IBM Plex Serif', serif;
  text-align: left;
  line-height: 30px;
  margin-bottom: 20px;
}

.text-muted{
  font-size: 20px;
}

.nav-link{
  color: rgb(59, 59, 59);
  font-size: 16px;
}

.inspirational-row{
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgba(230, 230, 230, 0.308);
}

.biography-text{
  padding-left: 40px !important;
}

.jumbotron-row{
  padding-top: 5%;
}

.biography-body{
  font-size: 18px;
}

/* .card:hover{
  background-color: rgba(199, 199, 199, 0.315);
} */

.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);

}

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #00cae9;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #00cae9;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

.card-img-top{
  height: 200px;
}

.card{
  margin-left: 5px !important;
  margin-right: 5px !important;
  /* width: 15%; */
}


.card-footer{
  padding: 0;
}

.card-footer a{
  font-size: 16px;
  font-weight: 600;
}




.treatment-info-text{
  font-size: 20px;
  text-align: left;
  margin-top: 20px;
  margin-left: 20px;
}

.table{
  background-color: rgba(235, 235, 235, 0.527);
  font-size: 18px;
}

.day{
  width: auto;
}